import {
    Stripe,
    StripeCardElementOptions,
    StripeElements,
    loadStripe,
  } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import Header from './Header';
import axios from "axios";
import {siteData} from './data';

const Checkout =  () => {

    const [session,setSession] = useState(null);
    const [stripe,setStripe] = useState(null);
    const [cardElement,setCardElement] = useState(null);
    const [cardComplete,setCardComplete] = useState(null);
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [city,setCity] = useState("");
    const [postal_code,setPostal_code] = useState("");
    const [address,setAddress] = useState("");
    const [pk,setPk] = useState("");
    const [sk,setSk] = useState("");
    const [price,setPrice] = useState("");

    useEffect(()=> {
      console.log("dududuiiiii")
      load();
    },[]);

 

    const load = async () =>{
        const stripe = await loadStripe(siteData.pk);
        setStripe(stripe);
        //const r = await stripe.createRadarSession();
        //console.log(r?.radarSession)
        //setSession(r?.radarSession)
        const cardElementOptions = {
          // Set your desired options for the Card Element
          // For example:
          style: {
            base: {
              fontFamily: 'Roboto, sans-serif',
              fontSize: '16px',
              color: '#333333',
            },
          },
          hidePostalCode: false,
        };
    
        const cardElemente = stripe
          ?.elements()
          .create('card', cardElementOptions);
        cardElemente?.mount('#card-element');
        cardElemente.on('change', (event) => {
          setCardComplete(event.complete)
          console.log('iddiddi')
        });
        setCardElement(cardElemente)
    }

    const reserver = async () => {
        const token = await stripe.createToken(cardElement);
        console.log(token);
        axios.post(`${siteData.url}:3000/create-card`,{
            token:token.token.id,
            email,
            name,
            city,
            price:siteData.price,
            postal_code,
            country:"FR",
            line1:address,
            stripeKey:siteData.sk,
            amount:49,

        })
        .then((res)=>{
          console.log(res.data)
        })
        .catch(e=>console.log(e));
    }

   
    return (
        <div >
            <Header />
            <p className='font-semibold text-2xl mx-6'>Reserver</p>

            <div className='flex flex-col space-y-2 justify-center items-center'>
                <input onChange={(e)=> setName(e.target.value)} placeholder='Nom complet' className='border border-gray-300 rounded-md outline-none w-96 p-2 '/>
                <input onChange={(e)=> setAddress(e.target.value)} placeholder='Adresse' className='border border-gray-300 rounded-md outline-none w-96 p-2 '/>
                <input onChange={(e)=> setCity(e.target.value)} placeholder='Ville' className='border border-gray-300 rounded-md outline-none w-96 p-2 '/>
                <input onChange={(e)=> setPostal_code(e.target.value)} placeholder='Code postal' className='border border-gray-300 rounded-md outline-none w-96 p-2 '/>
                <input onChange={(e)=> setEmail(e.target.value)} placeholder='Email' className='border border-gray-300 rounded-md outline-none w-96 p-2 '/>
            </div>


        <form className="mt-24" id="payment-form">
            <div className="p-4 mx-2 bg-gray-100" id="card-element"></div>

            <div id="card-errors" role="alert"></div>
        </form>

        <div className='flex items-center justify-center'>

{/*         <button onClick={load} className='bg-purple-600 mt-6 mx-auto rounded-md w-96 text-white p-2 '>Charger</button> */}
        <button onClick={reserver} className='bg-purple-600 mt-6 mx-auto rounded-md w-96 text-white p-2 '>Reserver</button>
        </div>
        </div>
    )
}

export default Checkout;
export const siteData = {
  "name": "FARINAD",
  "address": "17 rue jean gayral",
  "postCode": "31200",
  "city": "toulouse",
  "phone": "0977724310",
  "url": "https://farinad.com",
  "urlSub": "farinad.com",
  "price": "price_1PxW9QP2J4EuFHJlOGvvfy2A",
  "pk": "pk_live_51PxW3mP2J4EuFHJl6BSpQlhNEa8Fi2eVmG9oy6JYKmwlmriGocLNP6IFMB3XGwxbyTHxDgXzL8Ua74aYms9u2XBr00Q5znqZad",
  "sk": "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"
};